<template>
  <section id="dashboard-home">
    <b-row class="match-height">
      <b-col
        lg="6"
        md="12"
      >
        <analytics-timeline :data="data.timeline" />
      </b-col>
      <b-col
        lg="6"
        md="12"
      >
        <b-row class="match-height">
          <b-col
            lg="6"
            md="12"
          >
            <statistic-card-with-area-chart
              v-if="data.subscribersGained"
              icon="UsersIcon"
              :statistic="kFormatter(data.subscribersGained.analyticsData.subscribers)"
              statistic-title="Subscribers Gained"
              :chart-data="data.subscribersGained.series"
            />
          </b-col>
          <b-col
            lg="6"
            md="12"
          >
            <statistic-card-with-area-chart
              v-if="data.ordersRecevied"
              icon="PackageIcon"
              color="warning"
              :statistic="kFormatter(data.ordersRecevied.analyticsData.orders)"
              statistic-title="Orders Received"
              :chart-data="data.ordersRecevied.series"
            />
          </b-col>
        </b-row>
        <b-row class="match-height">
          <b-col
            lg="12"
            md="12"
          >
            <analytics-avg-sessions :data="data.avgSessions" />
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="6">
        <analytics-sales-radar-chart :data="data.salesChart" />
      </b-col>
      <b-col lg="6">
        <analytics-support-tracker :data="data.supportTracker" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col
        lg="12"
        md="12"
        cols="12"
      >
        <mlf-user-list />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'
import MLFUserList from '@mlfcore/components/user-list.vue'
import AnalyticsAvgSessions from './HomeAvgSessions.vue'
import AnalyticsSupportTracker from './HomeSupportTracker.vue'
import AnalyticsTimeline from './HomeTimeline.vue'
import AnalyticsSalesRadarChart from './HomeSalesRadarChart.vue'

export default {
  components: {
    BRow,
    BCol,
    AnalyticsAvgSessions,
    StatisticCardWithAreaChart,
    AnalyticsSupportTracker,
    AnalyticsTimeline,
    AnalyticsSalesRadarChart,
    'mlf-user-list': MLFUserList,
  },
  data() {
    return {
      data: {},
    }
  },
  created() {
    // data
    this.$http.get('/analytics/data')
      .then(response => { this.data = response.data })
  },
  methods: {
    kFormatter,
  },
}
</script>
